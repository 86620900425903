<template>
  <chip class="auction-registration-status" :class="getStatusClass()">
    {{ status.name }}
  </chip>
</template>

<script>
import { auctionRegistrationStatus as STATUS } from "@/enums";
import Chip from "@/components/GlobalComponents/Chip";

export default {
  components: { Chip },
  mixins: [],
  props: {
    status: {
      type: Object,
      default: () => ({})
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    getStatusClass() {
      switch (this.status.id) {
        case STATUS.PENDING:
          return "bg-warn fg-white";
        case STATUS.APPROVED:
          return "bg-success fg-white";
        case STATUS.REJECTED:
          return "bg-danger fg-white";
        default:
          break;
      }
    }
  }
};
</script>

<style lang="scss"></style>
